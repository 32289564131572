import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import AdminInfo from '../AdminInfo/AdminInfo';
import ColorConfig from '../ColorConfig/ColorConfig';
import DataInfo from '../DataInfo/DataInfo';
import UsersList from '../Users/UsersList';
import AdminHistoryLog from '../AdminHistoryLog/AdminHistoryLog';
import './AdminConfig.css';

export default function AdminConfigScreen() {
	return (
		<>
			<br />
			<h1 className='text-center'>Configuración</h1>
			<div className='container config-items'>
				<Accordion>
					<Accordion>
						<Accordion.Item eventKey='0'>
							<Accordion.Header>Cambiar informacion de la Empresa</Accordion.Header>
							<Accordion.Body>
								<AdminInfo></AdminInfo>
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>
				</Accordion>
				<Accordion>
					<Accordion.Item eventKey='0'>
						<Accordion.Header>Cambiar Tema/Colores</Accordion.Header>
						<Accordion.Body>
							<ColorConfig></ColorConfig>
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
				<Accordion>
					<Accordion.Item eventKey='0'>
						<Accordion.Header>Usuarios</Accordion.Header>
						<Accordion.Body>
							<UsersList></UsersList>
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
				<Accordion>
					<Accordion.Item eventKey='0'>
						<Accordion.Header>Mas datos</Accordion.Header>
						<Accordion.Body>
							<DataInfo></DataInfo>
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
				<Accordion>
					<Accordion.Item eventKey='0'>
						<Accordion.Header>Seleccionar modificaciones con seguimiento</Accordion.Header>
						<Accordion.Body>
							<AdminHistoryLog></AdminHistoryLog>
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
			</div>
		</>
	);
}
