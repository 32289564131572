import moment from 'moment';
import React, { useEffect, useReducer, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { MdAddCircleOutline, MdOtherHouses, MdOutlineAccountTree,MdInfoOutline } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import ContractService from '../../services/contract.service';
import { getError } from '../../utils';
import ContractDebts from '../Contracts/ContractDebts';
import ContractInfo from '../Contracts/ContractInfo';
import './ContractDetail.css';
import HistoryModal from '../../components/HistoryModal';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, contract: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'CLOSE_CONTRACT_REQUEST':
		return { ...state, loadingCloseContract: true };
	case 'CLOSE_CONTRACT_SUCCESS':
		return { ...state, loadingCloseContract: false };
	case 'CLOSE_CONTRACT_FAIL':
		return { ...state, loadingCloseContract: false, error: action.payload };
	default:
		return state;
	}
};

export default function ContractDetail() {
	const params = useParams();
	const navigate = useNavigate();

	const { id } = params;
	const [{ contract, loading, loadingCloseContract, error }, dispatch] = useReducer(reducer, {
		contract: {},
		loading: true,
		loadingCloseContract: false,
		error: '',
	});

	const [closeDate, setCloseDate] = useState(moment.utc().format('YYYY-MM-DD'));
	const [showModal, setShowModal] = useState(false);
	const [adShowModal, setAdShowModal] = useState(false);
	const [showHistoryLog, setShowHistoryLog] = useState(false);


	const openCloseContractModal = () => {
		setShowModal(true);
	};

	const handleCloseModal = () => {
		setShowModal(false);
	};
	const handleCloseAdModal = () => {
		setAdShowModal(false);
	};
	const handleOpenAdModal = () => {
		setAdShowModal(true);
	};
	const handleShowHistoryLog = () => {
		setShowHistoryLog(true);
	};

	const handleCloseHistoryLog = () => {
		setShowHistoryLog(false);
	};
	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await ContractService.getDetails(id);
				dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
				console.error(getError(error));
				toast.error('No se pudo obtener lista');
			}
		};
		fetchData();
	}, [id]);

	async function editAccountHandler() {
		navigate(`/AdminScreen/contracts/editContract/${contract._id}`);
	}

	async function newDebtHandler(e) {
		navigate(`/AdminScreen/contracts/contract/${id}/newDebt`);
		e.stopPropagation();
	}

	const newContractHandler = (contractData) => {
		navigate('/AdminScreen/newContract', { state: { currentContract: contractData } });
	};

	const checkCloseContract = async () => {
		if (moment.utc(closeDate).isBefore(moment.utc())){
			handleOpenAdModal();
		}else{
			closeContract();
		}
	};

	const closeContract = async () => {
		try {
			dispatch({ type: 'CLOSE_CONTRACT_REQUEST' });
			const requestData = {
				closeDate,
				status:'closed',
			};
			await ContractService.close(contract._id, requestData);
			dispatch({
				type: 'CLOSE_CONTRACT_SUCCESS',
			});
			toast.success('Contrato finalizado Correctamente');
			handleCloseModal();
			handleCloseAdModal();
		} catch (err) {
			dispatch({ type: 'CLOSE_CONTRACT' });
			toast.error(getError(err));
		}
	};

	return (
		<>
			<div className='container admin-con align-items-center'>
				{(loading || loadingCloseContract) ? (
					<LoadingBox></LoadingBox>
				) : (
					error ? (
						<MessageBox variant='danger'>{error}</MessageBox>
					) : null
				)}				<Row style={{ margin: '0 0 2.5% 0' }}>
					<Col style={{ alignSelf: 'center' }}>
						<h2>
							<MdOtherHouses></MdOtherHouses>Alquiler:{contract.customId}
						</h2>
						<Col>
							<span onClick={handleShowHistoryLog} style={{ cursor: 'pointer', textDecoration: 'underline' }} className="btn btn-link p-0">historial de cambios contrato</span>
						</Col>
						<HistoryModal model="contract" show={showHistoryLog} handleClose={handleCloseHistoryLog} id={id} isHistory={true}/>
						{contract.status === 'closed' ? (
							<p>	<span onClick={handleShowHistoryLog} style={{ cursor: 'pointer', textDecoration: 'underline', marginBottom:'5px' }} className="btn btn-link p-0"><MdInfoOutline/></span>
							Contrato Finalizado con fecha {moment.utc(contract.closeDate).format('DD-MM-YYYY')}
								<HistoryModal model="contract" show={showHistoryLog} handleClose={handleCloseHistoryLog} id={contract._id} isHistory={true}/>

							</p>
						) : null}
					</Col>
					<Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<Button
							style={{ backgroundColor: 'transparent!important' }}
							id='back-btn'
							className='btn m-2'
							value='back'
							onClick={() => navigate(-1)}
						>
							Atras
						</Button>
						{contract.status !== 'closed' ? (
							<>
								<Button
									className='btn btn-dark m-2'
									value='Editar Contrato'
									onClick={() => editAccountHandler()}
								>
									Editar Contrato
								</Button>
								<Button
									className='btn btn-dark m-2'
									value='Finalizar Alquiler'
									onClick={() => openCloseContractModal()}
								>
									Finalizar Alquiler
								</Button>
							</>
						) : null}
						{contract.status === 'closed' ? (
							<Button
								className='btn btn-dark m-2'
								value='nuevo Alquiler'
								onClick={() => newContractHandler(contract)}
							>
								Nuevo Alquiler a partir del actual
							</Button>
						) : null}
					</Col>
					<Modal show={showModal} onHide={handleCloseModal}>
						<Modal.Header closeButton>
							<Modal.Title>Confirmar Finalizar Contrato</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Form.Group className='mb-3 col-12 form-group required' controlId='date'>
								<Form.Label>Fecha de finalización</Form.Label>
								<Form.Control
									type='date'
									value={closeDate}
									onChange={(e) => setCloseDate(e.target.value)}
								></Form.Control>
							</Form.Group>
						</Modal.Body>
						<Modal.Footer>
							<Button variant='secondary' onClick={handleCloseModal}>
								Volver
							</Button>
							<Button variant='primary' onClick={checkCloseContract}>
								Confirmar
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={adShowModal}>
						<Modal.Header closeButton>
							<Modal.Title>Advertencia Finalizar Contrato</Modal.Title>
						</Modal.Header>
						<Modal.Body>
						La fecha seleccionada es menor a la actual, al confirmar ésta fecha de finalización se eliminaran todas las cuotas
						y los pagos vinculados, generados con posterioridad al mes actual.
						</Modal.Body>
						<Modal.Footer>
							<Button variant='secondary' onClick={handleCloseAdModal}>
								Cancelar
							</Button>
							<Button variant='primary' onClick={closeContract}>
								Confirmar
							</Button>
						</Modal.Footer>
					</Modal>
				</Row>
				<div className='container'>
					<div className='jumbotron'>
						<ContractInfo></ContractInfo>
					</div>
					<div className='jumbotron related-data'>
						<h2 style={{ marginBottom: '2.5%' }}>
							<MdOutlineAccountTree></MdOutlineAccountTree>Datos Relacionados
						</h2>
						<Accordion>
							<Accordion>
								<Accordion.Item eventKey='0'>
									<Accordion.Header>
										Deudas
										<Button
											id='add-btn'
											onClick={(e) => newDebtHandler(e)}
											disabled={contract.status === 'closed'}
										>
											<MdAddCircleOutline></MdAddCircleOutline>Agregar
										</Button>
									</Accordion.Header>
									<Accordion.Body>
										<ContractDebts></ContractDebts>
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
						</Accordion>
					</div>
				</div>
			</div>
		</>
	);
}
