import React, { useEffect, useReducer } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import DynamicListTableNew from '../../components/DynamicListTableNew';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import ContractService from '../../services/contract.service';
import { getError } from '../../utils';
import { contractsColumns } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, contracts: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true,
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	default:
		return state;
	}
};

function AccountContracts() {
	const [{ loading, error, contracts, successDelete }, dispatch] = useReducer(reducer, {
		contracts: [],
		loading: true,
		error: '',
	});

	const params = useParams();
	const navigate = useNavigate();
	const { id } = params;

	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				const { data } = await ContractService.getContractsForAccount(id);
				dispatch({ type: 'FETCH_SUCCESS', payload: data });
			} catch (err) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
				console.error(getError(err));
				toast.error('No se pudo obtener cuentas');
			}
		};
		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		}
		fetchData();
	}, [successDelete]);

	async function deletePaymentHandler(contract) {
		if (window.confirm('Seguro desea eliminar el contrato?')) {
			try {
				dispatch({ type: 'DELETE_REQUEST' });
				await ContractService.delete(contract._id);
				dispatch({ type: 'DELETE_SUCCESS' });
				toast.success('Contrato Eliminado');
			} catch (err) {
				dispatch({ type: 'DELETE_FAIL' });
				console.error(getError(err));
				toast.error('Error al eliminar contrato');
			}
		}
	}

	async function editPaymentHandler(contract) {
		navigate(`/AdminScreen/contracts/editContract/${contract._id}`);
	}

	return loading ? (
		<LoadingBox></LoadingBox>
	) : error ? (
		<MessageBox variant='danger'>{error}</MessageBox>
	) : (
		<div>
			<Helmet>
				<title>Lista de Alquileres</title>
			</Helmet>

			<div className='container admin-con'>
				<div className='row adminProdBtnRow align-items-center'>
					<DynamicListTableNew
						data={contracts}
						loading={loading}
						error={error}
						actionButtons={{
							editHandler: editPaymentHandler,
							deleteHandler: deletePaymentHandler,
						}}
						dataName='contracts'
						showCheckboxColumn={false}
						links={true}
						frontPaginator={true}
						exportOption={true}
						columnInfo={contractsColumns}
					/>
				</div>
			</div>
		</div>
	);
}
export default AccountContracts;
