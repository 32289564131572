import React, { useEffect, useReducer } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import DynamicListTableNew from '../../components/DynamicListTableNew';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import AccountService from '../../services/account.service';
import CreditService from '../../services/credit.service';
import { getError } from '../../utils';
import { creditsColumns } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, credits: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true,
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	default:
		return state;
	}
};

function AccountCredits() {
	const [{ loading, error, credits, successDelete }, dispatch] = useReducer(reducer, {
		credits: [],
		loading: true,
		error: '',
	});

	const params = useParams();
	const navigate = useNavigate();

	const { id } = params;


	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				const { data } = await AccountService.getCreditsForAccountId(id);
				dispatch({ type: 'FETCH_SUCCESS', payload: data });
			} catch (err) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
				console.error(getError(err));
				toast.error('No se pudo obtener lista');
			}
		};
		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		}
		fetchData();
	}, [successDelete]);

	async function deleteCreditHandler(credit) {
		if (window.confirm('Seguro desea eliminar acreedor ?')) {
			try {
				dispatch({ type: 'DELETE_REQUEST' });
				await CreditService.delete(credit._id);
				dispatch({ type: 'DELETE_SUCCESS' });
				toast.success('Acreedor Eliminado');
			} catch (err) {
				dispatch({ type: 'DELETE_FAIL' });
				console.error(getError(error));
				toast.error('Error al eliminar acreedor');
			}
		}
	}

	async function editCreditHandler(credit) {
		navigate(`/AdminScreen/credits/editCredit/${credit._id}`);
	}
	// async function newCreditHandler() {
	// 	navigate('/AdminScreen/newCredit');
	// }

	return loading ? (
		<LoadingBox></LoadingBox>
	) : error ? (
		<MessageBox variant='danger'>{error}</MessageBox>
	) : (
		<div>
			<Helmet>
				<title>Lista de acreedores</title>
			</Helmet>

			<div className='container admin-con'>
				<div className='row adminProdBtnRow align-items-center'></div>
				<DynamicListTableNew
					data={credits}
					loading={loading}
					error={error}
					actionButtons={{
						editHandler: editCreditHandler,
						deleteHandler: deleteCreditHandler,
					}}
					dataName='credits'
					showCheckboxColumn={false}
					links={true}
					frontPaginator={true}
					exportOption={true}
					columnInfo={creditsColumns}
				/>
			</div>
		</div>
	);
}

export default AccountCredits;
