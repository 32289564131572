import React, { useEffect, useReducer, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Helmet } from 'react-helmet-async';
import { MdLibraryAdd } from 'react-icons/md';
import { toast } from 'react-toastify';
import LoadingBox from '../../components/LoadingBox';
import { getError } from '../../utils';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useNavigate } from 'react-router-dom';
import CustomTypeahead from '../../components/CustomTypeahead';
import GenericDropdown from '../../components/SelectDropdown';
import debtService from '../../services/debt.service';
import paymentService from '../../services/payment.service';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loadingFetch: true };
	case 'FETCH_SUCCESS':
		return { ...state, debts: action.payload, loadingFetch: false };
	case 'FETCH_FAIL':
		return { ...state, loadingFetch: false, errorFetch: action.payload };
	case 'CREATE_REQUEST':
		return { ...state, loadingCreate: true };
	case 'CREATE_SUCCESS':
		return {
			...state,
			loadingCreate: false,
		};
	case 'CREATE_FAIL':
		return { ...state, loadingCreate: false };
	default:
		return state;
	}
};
function AddPayment() {
	const navigate = useNavigate();

	const currencies = ['USD', 'UYU'];

	const [amount, setAmount] = useState();
	const [date, setDate] = useState();
	const [debt, setDebt] = useState();
	const [debtDueTmp, setDebtDueTmp] = useState([]);
	const [disableDebtDue, setDisableDebtDue] = useState(true);
	const [folder, setFolder] = useState();
	const [observations, setObservations] = useState();
	const [comprobante, setComprobante] = useState('');
	const [uploading, setUploading] = useState(false);
	const [debtDue, setDebtDue] = useState();
	const [generatedDues, setGeneratedDues] = useState();
	const [currency, setCurrency] = useState();
	const [unpaidAmount, setUnpaidAmount] = useState();

	const [{ loadingFetch, loadingCreate, debts }, dispatch] = useReducer(reducer, {
		loadingFetch: true,
		debts: [],
		loadingCreate: false,
		error: '',
	});

	useEffect(() => {
		fetchData();

	}, []);
	//#region REQUESTS
	const fetchData = async () => {
		dispatch({ type: 'FETCH_REQUEST' });
		try {
			const result = await debtService.list(
				{ populate: false, paid: false });
			dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
		} catch (error) {
			dispatch({ type: 'FETCH_FAIL', payload: error.message });
		}
	};
	const uploadComprobante = async () => {
		setUploading(true);
		if (!comprobante) {
			toast.warning('Debe seleccionar un archivo');
			setUploading(false);
			return;
		}

		const file = comprobante[0];
		const formData = new FormData();
		let r = Math.random().toString(36).substring(7);
		formData.append('file', file, (file.name || '').replaceAll(' ', '') + r);

		formData.append('documentId', comprobante._id);
		try {
			const response = await paymentService.uploadComprobante(formData);
			setComprobante(response.data);
			setUploading(false);
			toast.success('Archivo Subido');
		} catch (ex) {
			setUploading(false);
			toast.error(getError(ex));
		}
	};
	const addPaymentHandler = async () => {
		try {
			dispatch({ type: 'CREATE_REQUEST' });
			if (!debt) {
				toast.error('Debe seleccionar deudor.');
				dispatch({ type: 'CREATE_FAIL' });
				return;
			}
			await paymentService.create({
				amount,
				date,
				debt: debt[0] || debt,
				folder,
				observations,
				comprobante,
				debtDue,
				currency,
			});
			toast.success('Cobro creado');
			navigate(-1);
		} catch (ex) {
			toast.error(getError(ex));
		}
	};

	const loadGeneratedDues = async (selectedDebt) => {
		try {
			const {data} = await debtService.getDuesForDebt(selectedDebt._id);
			setGeneratedDues(data);
		} catch(ex) {
			console.error(ex);
			toast.error('No se pudo cargar las cuotas de la deuda');
		}
	};
	//#endregion	

	const [isInvalid, setIsInvalid] = useState(false);
	const [isDebtDueInvalid, setIsDebtDueInvalid] = useState(false);
	
	const handleDebtChange = (selected) => {
		if (!selected || selected.length === 0) {
			const selectedDebt = selected[0];
			const isValid = debts.includes(selectedDebt);
			setIsInvalid(!isValid);
			setDebt(null);
			setDebtDue(null);
			setDebtDueTmp([]);
			setCurrency('');
			setFolder('');
			setDisableDebtDue(true);
		} else {
			const selectedDebt = selected[0];
			const isValid = debts.includes(selectedDebt);
			setIsInvalid(!isValid);
			setDebt(selectedDebt);
			loadGeneratedDues(selectedDebt);
			setCurrency(selectedDebt.currency);
			setFolder(selectedDebt.owner.folder);
			setDisableDebtDue(false);
		}
	};

	const handleDebtDueChange = (selected) => {
		if (generatedDues) {
			if (!selected || selected.length === 0) {
				const selectedDebtDue = selected[0];
				const isValid = generatedDues.includes(selectedDebtDue);
				setIsDebtDueInvalid(!isValid);
				setDebtDue(null);
				setDebtDueTmp([]);
				setUnpaidAmount(null);
			} else {
				setDebtDueTmp(selected);
				const selectedDebtDue = selected[0];
				const isValid = generatedDues.includes(selectedDebtDue);
				setIsDebtDueInvalid(!isValid);
				setDebtDue(selectedDebtDue);
				setUnpaidAmount(selectedDebtDue.unpaidAmount);
			}
		}
	};
	const handleAmountChange = (e) => {
		const value = e.target.value;
		if (value <= unpaidAmount) {
			setAmount(value);
		}
	};
	return (
		<div>
			<Modal size='lg' show={true} animation={false}>
				{(loadingCreate || loadingFetch || uploading) && <LoadingBox></LoadingBox>}
				<div className='container admin-con align-items-center'>
					<Helmet>
						<title>Agregar Cobro</title>
					</Helmet>
					<h1>
						<MdLibraryAdd></MdLibraryAdd>Agregar Cobro
					</h1>
					<Form className='admin-form' onSubmit={(e) => e.preventDefault()}>
						<div className='row align-items-center'>
							<Form.Group className='mb-3 col-6 form-group required'>
								<Form.Label>Deuda</Form.Label>
								<CustomTypeahead
									id='debts'
									labelKey={(option) => `${option.customId}`}
									onChange={handleDebtChange}
									options={debts}
									placeholder='Buscar deuda...'
									linkToCreate='/AdminScreen/newDebt'
									newOption='nueva deuda'
								/>
								{isInvalid && <div style={{ color: 'red' }}>Deuda invalida</div>}
							</Form.Group>
							<Form.Group className='mb-3 col-6 form-group required'>
								<Form.Label>Cuota Deuda</Form.Label>
								<CustomTypeahead
									id='debts'
									labelKey={(option) => `${option.customId}`}
									onChange={handleDebtDueChange}
									options={generatedDues ? generatedDues.filter((due) => !due.paid) : []}
									disabled={disableDebtDue}
									selected={debtDueTmp}
									placeholder='Buscar cuota...'
									linkToCreate=''
									newOption={null}
									newSelectionPrefix={null}
								/>
								{isDebtDueInvalid && <div style={{ color: 'red' }}>invalido</div>}
							</Form.Group>
							<Form.Group className='mb-3 col-6 form-group required' controlId='amount'>
								<Form.Label>Monto</Form.Label>
								<Form.Control
									type='number'
									defaultValue={unpaidAmount}
									onChange={(e) => handleAmountChange(e)}
									min={0}
									onInput={(e) => {
										const inputValue = parseFloat(e.target.value);
										const maxValue = debtDue ? unpaidAmount : null;

										if (maxValue !== null && inputValue > maxValue) {
											e.target.value = maxValue; // Set the value to the maximum allowed value
										}
									}}
								/>
							</Form.Group>
							<Form.Group className='mb-3 col-6 form-group required' controlId='date'>
								<Form.Label>Fecha</Form.Label>
								<Form.Control type='date' onChange={(e) => setDate(e.target.value)}></Form.Control>
							</Form.Group>
							<Form.Group as={Col} className='mb-3 col-3' controlId='currency'>
								<GenericDropdown
									label='Moneda'
									items={currencies}
									selected={currency}
									onChange={(e) => setCurrency(e.value)}
								/>
							</Form.Group>
							<Form.Group as={Col} className='mb-3 col-6' controlId='folder'>
								<Form.Label>Carpeta</Form.Label>
								<Form.Control
									onChange={(e) => setFolder(e.target.value)}
									value={folder}
								></Form.Control>
							</Form.Group>
							<Row>
								<Form.Group className='col-10' controlid='comprobante'>
									<Form.Label>Comprobante</Form.Label>
									<Form.Control
										type='file'
										required
										onChange={(e) => setComprobante(e.target.files)}
									></Form.Control>
									<Form.Control.Feedback type='invalid'>Seleccione Archivo</Form.Control.Feedback>
								</Form.Group>
								<Button
									className='col-2'
									style={{ height: '30px', marginTop: '32px', padding: '0' }}
									onClick={() => uploadComprobante(comprobante)}
								>
									subir
								</Button>
							</Row>
							<Form.Group style={{ marginBottom: '5%' }} className='col-12' controlId='field'>
								<Form.Label>Observaciones</Form.Label>
								<Form.Control
									type='text'
									as='textarea'
									rows={10}
									onChange={(e) => setObservations(e.target.value)}
								></Form.Control>
							</Form.Group>
						</div>
						<Row className='modal-btns'>
							<Col>
								<Button className='cancelar' onClick={() => navigate(-1)}>
									Cancelar
								</Button>
							</Col>
							<Col>
								<Button className='guardar' onClick={addPaymentHandler}>
									Agregar
								</Button>
							</Col>
						</Row>
					</Form>
				</div>
			</Modal>
		</div>
	);
}
export default AddPayment;
