import React, { useEffect, useReducer } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import DynamicListTableNew from '../../components/DynamicListTableNew';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import ContactService from '../../services/contact.service';
import { getError } from '../../utils';
import { contactsColumns } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, contacts: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true,
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	default:
		return state;
	}
};

function AccountContacts() {
	const [{ loading, error, contacts, successDelete }, dispatch] = useReducer(reducer, {
		contacts: [],
		loading: true,
		error: '',
	});

	const params = useParams();
	const navigate = useNavigate();

	const { id } = params;

	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				const { data } = await ContactService.getContactsForAccount(id);
				dispatch({ type: 'FETCH_SUCCESS', payload: data });
			} catch (err) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
				console.error(err);
				toast.error('No se pudo obtener lista');
			}
		};
		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		}
		fetchData();
	}, [successDelete]);

	async function deleteContactHandler(contact) {
		if (window.confirm('Seguro desea eliminar el contacto?')) {
			try {
				dispatch({ type: 'DELETE_REQUEST' });
				await ContactService.delete(contact._id);
				dispatch({ type: 'DELETE_SUCCESS' });
				toast.success('Contacto Eliminado');
			} catch (err) {
				dispatch({ type: 'DELETE_FAIL' });
				console.error(getError(err));
				toast.error('Error al eliminar acreedor');
			}
		}
	}

	async function editContactHandler(contact) {
		navigate(`/AdminScreen/contacts/editContact/${contact._id}`);
	}

	return loading ? (
		<LoadingBox></LoadingBox>
	) : error ? (
		<MessageBox variant='danger'>{error}</MessageBox>
	) : (
		<div>
			<Helmet>
				<title>Lista de Contactos</title>
			</Helmet>

			<div className='container admin-con'>
				<div className='row adminProdBtnRow align-items-center'>
					{/* <button className='btn btn-info btn-prod-admin'><FaFilter></FaFilter>Filtrar</button> */}
					{/* <button className='btn btn-danger btn-prod-admin'><Link to='/AdminScreen/AgregarProducto'><FaPlusCircle></FaPlusCircle>Agregar Productos</Link></button> */}
				</div>

				<DynamicListTableNew
					data={contacts}
					loading={loading}
					error={error}
					actionButtons={{
						editHandler: editContactHandler,
						deleteHandler: deleteContactHandler,
					}}
					dataName='contacts'
					showCheckboxColumn={false}
					links={true}
					frontPaginator={true}
					exportOption={true}
					columnInfo={contactsColumns}
				/>
			</div>
		</div>
	);
}

export default AccountContacts;
