import React, { useEffect, useReducer, useState } from 'react';
import {
	Button,
	Col,
	Dropdown,
	Row,
	SplitButton
} from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { FaFilter } from 'react-icons/fa';
import { MdClose, MdOutlineCorporateFare } from 'react-icons/md';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import DynamicListTableNew from '../../components/DynamicListTableNew';
import FilterForm from '../../components/FilterForm';
import ListButtons from '../../components/ListButtons';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import SearchBox from '../../components/SearchBox';
import AccountService from '../../services/account.service';
import { getError } from '../../utils';
import { accountColumns } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return {
			...state,
			accounts: action.payload.accounts,
			page: action.payload.page,
			pages: action.payload.pages,
			countAccounts: action.payload.countAccounts,
			pageSize: action.payload.pageSize,
			loading: false,
		};
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true,
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	default:
		return state;
	}
};

function AccountList() {
	const [{ loading, error, accounts, successDelete, pageSize, countAccounts }, dispatch] =
		useReducer(reducer, {
			accounts: [],
			loading: true,
			error: '',
			pageSize: 0,
			countAccounts: 0,
		});

	const [loadingInitial, setLoadingInitial] = useState(true);
	const navigate = useNavigate();

	const { search } = useLocation();
	const sp = new URLSearchParams(search); // /search?category=Shirts
	const type = sp.get('type') || 'all';
	const accountType = sp.get('accountType') || 'all';
	const query = sp.get('query') || 'all';
	const currency = sp.get('currency') || 'all';
	const [filtered, setFiltered] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const page = sp.get('page') || 1;
	const types = ['acreedores', 'deudores'];
	const column = sp.get('column') || 'name';
	const direction = sp.get('direction') || 'asc';


	const myTitle = (
		<h1 className='d-flex justify-content-start align-items-center list-title-dropdown'>
			{accountType == 'all'
				? 'Todas las Cuentas'
				: accountType[0].toUpperCase() + accountType.substring(1)}
		</h1>
	);

	const buttonItems = [
		{
			label: 'Agregar Cuenta',
			onClick: () => newAccountHandler(),
		},
	];
	const onPageChange = (page) => {
		navigate(getFilterUrl({ page: page }));
	};

	const handleDropdownToggle = () => {
		setIsOpen(!isOpen);
	};

	const [filterOptions, setFilterOptions] = useState({
		formType: 'Cuentas',
		accountType: accountType,
		type: type,
		currency: currency,
		query: query,
	});

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFilterOptions((prevOptions) => ({
			...prevOptions,
			[name]: value,
			formType: filterOptions.formType,
		}));
	};

	const handleConceptInputChange = (value) => {
		setFilterOptions((prevOptions) => ({
			...prevOptions,
			accountType: value,
		}));
	};

	const handleApplyFilter = () => {
		const { type, currency, accountType } = filterOptions;
		const filter = {
			type,
			currency,
			accountType,
			page: 1,
			query,
		};
		const filterUrl = getFilterUrl(filter);
		navigate(filterUrl);
		setFiltered(true);
	};
	const getFilterUrl = (filter) => {
		const { type, query, currency, accountType } = filterOptions;
		const filterQuery = filter.query || query;
		const filterPage = filter.page || 1;
		const filterType = type || 'all';
		const filterCurrency = currency || '';
		const filterAccountType = accountType || accountType;

		const params = new URLSearchParams();
		params.append('page', filterPage);
		params.append('type', filterType);
		params.append('accountType', filterAccountType);
		params.append('query', filterQuery);
		params.append('currency', filterCurrency);

		return `/AdminScreen/accounts/search?${params.toString()}`;
	};

	useEffect(() => {
		handleApplyFilter();
	}, [filterOptions.accountType, filtered]);

	const handleClearFilter = () => {
		setFilterOptions({
			formType: 'Cuentas',
			type: 'all',
			accountType: 'all',
			currency: 'all',
			query: 'all',
		});
		setFiltered(false);
	};

	const handleSort = (column) => {
		const newSortDirection = column === column && direction === 'desc' ? 'asc' : 'desc';
		const newURL = `/AdminScreen/accounts/search?page=${page}&type=${type}&accountType=${accountType}&query=${query}&currency=${currency}&column=${column}&direction=${newSortDirection}`;
		navigate(newURL);
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				const { data } = await AccountService.search(
					`page=${page}&type=${type}&accountType=${accountType}&query=${query}&currency=${currency}&column=${column}&direction=${direction}`,
				);
				dispatch({ type: 'FETCH_SUCCESS', payload: data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			} finally {
				setLoadingInitial(false);
			}
		};
		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		} else {
			fetchData();
		}
	}, [successDelete, type, column, direction, query, accountType, currency, page]);

	async function deleteAccountHandler(account) {
		if (window.confirm('Seguro desea eliminar la cuenta?')) {
			try {
				dispatch({ type: 'DELETE_REQUEST' });
				await AccountService.delete(account._id);
				dispatch({ type: 'DELETE_SUCCESS' });
				toast.success('Cuenta Eliminada');
			} catch (err) {
				toast.error(getError(err));
				dispatch({ type: 'DELETE_FAIL' });
				toast.error('Error al eliminar cuenta');
			}
		}
	}
	async function editAccountHandler(account) {
		navigate(`/AdminScreen/accounts/editAccount/${account._id}`);
	}
	async function seeDetails(account) {
		navigate(`/AdminScreen/accounts/account/${account._id}`);
	}
	async function newAccountHandler() {
		navigate('/AdminScreen/newAccount');
	}

	return loadingInitial ? (
		<LoadingBox loading={loadingInitial}></LoadingBox>
	) : error ? (
		<MessageBox variant='danger'>{error}</MessageBox>
	) : (
		<div>
			<Helmet>
				<title>Lista de Cuentas</title>
			</Helmet>

			<div className='admin-con'>
				<Row>
					<Col>
						<Row className='d-flex justify-content-center'>
							<Row>
								<h2 className='d-flex justify-content-start align-items-center list-title'>
									<MdOutlineCorporateFare></MdOutlineCorporateFare>Cuentas
								</h2>
							</Row>
							<Row className='title-btns-row'>
								<SplitButton
									className='btn title-dropdown text-align-start p-0'
									style={{ width: 'fit-content' }}
									align='end'
									title={myTitle}
								>
									<Dropdown.Item onClick={() => handleConceptInputChange('all')} eventKey='all'>
										Todos
									</Dropdown.Item>
									{types.map((accountType, index) => (
										<Dropdown.Item
											key={index}
											value={accountType}
											onClick={() => handleConceptInputChange(accountType)}
											eventKey={accountType}
										>
											{accountType}
										</Dropdown.Item>
									))}
								</SplitButton>
								<ListButtons buttonItems={buttonItems} />
							</Row>
							<Row className='d-flex justify-content-end align-items-center list-title'>
								{query !== 'all' ? (
									<Col>
										Resultados para: {query}
										<Link
											className='btn-primary-search'
											as={Link}
											to={getFilterUrl({ query: 'all' })}
										>
											<MdClose />
										</Link>
									</Col>
								) : (
									''
								)}
								<Col className='col-lg-3 d-flex justify-content-end'>
									<SearchBox getFilterUrl={getFilterUrl} navigate={navigate} />
								</Col>
								<Col className='col-lg-1 d-flex justify-content-end w-auto'>
									<Button
										className={
											isOpen
												? 'btn btn-dark btn-dark-open btn-sm m-2'
												: 'btn btn-dark btn-dark m-2 btn-sm'
										}
										onClick={handleDropdownToggle}
									>
										<FaFilter />
									</Button>
								</Col>
							</Row>
						</Row>
					</Col>
				</Row>
				<div className={isOpen ? 'table-container filter-opened' : 'table-container'}>
					{isOpen && (
						<FilterForm
							filterOptions={filterOptions}
							isOpen={isOpen}
							handleDropdownToggle={handleDropdownToggle}
							handleInputChange={handleInputChange}
							clearFilter={() => handleClearFilter('Cuentas')}
							handleApplyFilter={() => handleApplyFilter('Cuentas')}
						/>
					)}
					<DynamicListTableNew
						data={accounts}
						loading={loading}
						error={error}
						count={countAccounts}
						onPageChange={onPageChange}
						page={parseInt(page)}
						pageSize={pageSize}
						handleSort={handleSort}
						sortColumn={column}
						direction={direction}
						actionButtons={{
							seeDetails: seeDetails,
							editHandler: editAccountHandler,
							deleteHandler: deleteAccountHandler,
						}}
						columnInfo={accountColumns}
						dataName='accounts'
						showCheckboxColumn={false}
					/>
				</div>
			</div>
		</div>
	);
}

export default AccountList;
