import moment from 'moment';
import React, { useEffect, useReducer } from 'react';
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import CreditUpdatesService from '../services/creditUpdates.service';
import DebtUpdatesService from '../services/debtUpdates.service';
import HistoryLogsService from '../services/historyLog.service';
import LoadingBox from './LoadingBox';
import MessageBox from './MessageBox';
import { debtsColumns,creditsColumns, accountColumns, contactsColumns,contractsColumns,assetsColumns,
	assetSalesColumns , debtDuesColumns, creditDuesColumns, billsColumns,relWarrantiesColumns,paymentsColumns,
	creditPaymentsColumns} from '../utils';


const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_DEBTUPDATES_REQUEST':
		return { ...state, loadingDebt: true };
	case 'FETCH_DEBTUPDATES_SUCCESS':
		return { ...state, debtUpdates: action.payload, loadingDebt: false };
	case 'FETCH_DEBTUPDATES_FAIL':
		return { ...state, loadingDebt: false, error: action.payload };
	case 'FETCH_CREDITUPDATES_REQUEST':
		return { ...state, loadingCredit: true };
	case 'FETCH_CREDITUPDATES_SUCCESS':
		return { ...state, creditUpdates: action.payload, loadingCredit: false };
	case 'FETCH_CREDITUPDATES_FAIL':
		return { ...state, loadingCredit: false, error: action.payload };
	case 'FETCH_HISTORYLOG_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_HISTORYLOG_SUCCESS':
		return { ...state, historyLogs: action.payload, loading: false };
	case 'FETCH_HISTORYLOG_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

function HistoryModal({ show, handleClose, model, id, isHistory }) {
	const [{ error, loading,loadingCredit,loadingDebt,debtUpdates,creditUpdates, historyLogs }, dispatch] = useReducer(
		reducer,
		{
			debtUpdates: [],
			creditUpdates:[],
			historyLogs: [],
			loading: false,
			loadingCredit:false,
			loadingDebt:false,
			error: '',
		},
	);

	const getColumnLabelsAndTypes = (columns) => {
		const labelsAndTypes = {};
		columns.forEach(column => {
			const key = Object.keys(column)[0];
			labelsAndTypes[key] = {
				label: column[key].label,
				type: column[key].type
			};
		});
		return labelsAndTypes;
	};
	
	let columnInfo = {};
	if (model === 'debt') {
		columnInfo = getColumnLabelsAndTypes(debtsColumns);
	} else if (model === 'credit') {
		columnInfo = getColumnLabelsAndTypes(creditsColumns);
	} else if (model === 'contract') {
		columnInfo = getColumnLabelsAndTypes(contractsColumns);
	} else if (model === 'account') {
		columnInfo = getColumnLabelsAndTypes(accountColumns);
	} else if (model === 'asset') {
		columnInfo = getColumnLabelsAndTypes(assetsColumns);
	} else if (model === 'contact') {
		columnInfo = getColumnLabelsAndTypes(contactsColumns);
	} else if (model === 'assetSale') {
		columnInfo = getColumnLabelsAndTypes(assetSalesColumns);
	} else if (model === 'debtDue') {
		columnInfo = getColumnLabelsAndTypes(debtDuesColumns);
	} else if (model === 'creditDue') {
		columnInfo = getColumnLabelsAndTypes(creditDuesColumns);
	} else if (model === 'bill') {
		columnInfo = getColumnLabelsAndTypes(billsColumns);
	} else if (model === 'relWarranty') {
		columnInfo = getColumnLabelsAndTypes(relWarrantiesColumns);
	} else if (model === 'payment') {
		columnInfo = getColumnLabelsAndTypes(paymentsColumns);
	} else if (model === 'creditPayment') {
		columnInfo = getColumnLabelsAndTypes(creditPaymentsColumns);
	}
	


	const displayValue = (value, type) => {
		if (type === 'boolean') {
			return value ? 'Si' : 'No';
		}
		if (type === 'date') {
			const isValidDateFormat = moment.utc(value, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).isValid();
			return isValidDateFormat ? moment.utc(value).format('DD-MM-YYYY') : 'N/A';
		}
		if (value === 'closed') {
			return 'Finalizado';
		}
		return value || 'N/A';
	};

	useEffect(() => {
		if (id) {
			const fetchData = async () => {
				if (model === 'debt'){
					dispatch({ type: 'FETCH_DEBTUPDATES_REQUEST' });
					try {
						const result = await DebtUpdatesService.listFromDebtId(id);
						dispatch({ type: 'FETCH_DEBTUPDATES_SUCCESS', payload: result.data });
					} catch (error) {
						dispatch({ type: 'FETCH_DEBTUPDATES_FAIL', payload: error.message });
					}
				}
				if (model === 'credit'){
					dispatch({ type: 'FETCH_CREDITUPDATES_REQUEST' });
					try {
						const result = await CreditUpdatesService.listFromCreditId(id);
						dispatch({ type: 'FETCH_CREDITUPDATES_SUCCESS', payload: result.data });
					} catch (error) {
						dispatch({ type: 'FETCH_CREDITUPDATES_FAIL', payload: error.message });
					}
				}
			};
			const fetchHistoryData = async () => {
				dispatch({ type: 'FETCH_HISTORYLOG_REQUEST' });
				try {
					const result = await HistoryLogsService.listFromId(id);
					dispatch({ type: 'FETCH_HISTORYLOG_SUCCESS', payload: result.data });
				} catch (error) {
					dispatch({ type: 'FETCH_HISTORYLOG_FAIL', payload: error.message });
				}
			};
			if (isHistory){
				fetchHistoryData();}
			else{
				fetchData();}
		}
	}, [id]);

	const updates = model === 'debt' ? debtUpdates : model === 'credit' ? creditUpdates : [];


	return (
		<>
			{(loading || loadingCredit || loadingDebt)? (
				<LoadingBox></LoadingBox>
			) : 
				error ? (
					<MessageBox variant='danger'>{error}</MessageBox>
				) : !isHistory ? 
					(<Modal show={show} onHide={handleClose} size='md'>
						<Modal.Header closeButton>
							<Modal.Title>Historial de cambios</Modal.Title>
						</Modal.Header>
						<Modal.Body style={{ maxHeight: '60vh', overflowY: 'auto' }}>
							<>
								{updates.length != 0? (updates.map((change, index) => (
									<Row key={index} className="mb-3">
										<Col>
											<Card style={{ position: 'relative' }}>
												<div className="d-flex justify-content-between align-items-center p-2 bg-light">
													<span>Modificación de usuario {change.updatedDateBy.name}</span>
													<span>{moment.utc(change.createdAt).format('DD-MM-YYYY HH:mm')}</span>
												</div>
												<Card.Body className='text-center'>
											La fecha de primer vencimiento fue modificada:<br />
													<strong> {moment.utc(change.firstDueExpirationBefore).format('DD-MM-YYYY')}</strong>
													{' --------->'} <strong>{moment.utc(change.firstDueExpirationUpdated).format('DD-MM-YYYY')}</strong><br />
											Opción seleccionada: <strong>{change.updateDateOption}</strong>
												</Card.Body>
											</Card>
										</Col>
									</Row>
								))):(<MessageBox variant='danger'>No hay cambios de fecha registrados</MessageBox>)}
							</>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={handleClose}>Cerrar historial</Button>
						</Modal.Footer>
					</Modal>):
					(<Modal show={show} onHide={handleClose} size='md'>
						<Modal.Header closeButton>
							<Modal.Title>Historial de cambios</Modal.Title>
						</Modal.Header>
						<Modal.Body style={{ maxHeight: '60vh', overflowY: 'auto' }}>
							<>
								{historyLogs && historyLogs.length !== 0 ? (
									historyLogs.map((change, index) => (
										<Row key={index} className="mb-3">
											<Col>
												{change.operacion === 'update' ?(
													<Card style={{ position: 'relative' }}>
														<div className="d-flex justify-content-between align-items-center p-2 bg-light">
															<span>{`Modificación realizada por ${change.responsable? change.responsable.name : ''}`}</span>
															<span>{moment.utc(change.createdAt).format('DD-MM-YYYY HH:mm')}</span>
														</div>
														<Card.Body className='text-center'>
															{change.cambios.map((cambio, cambioIndex) => (
																<div key={cambioIndex}>
																	<span>{`Campo modificado: ${columnInfo[cambio.campo] ? columnInfo[cambio.campo].label : cambio.campo}`}</span><br />
																	<span>{`Valor anterior: ${columnInfo[cambio.campo] ? displayValue(cambio.valorAnterior, columnInfo[cambio.campo].type): cambio.valorAnterior}`}</span><br />
																	<span>{`Nuevo valor: ${columnInfo[cambio.campo] ? displayValue(cambio.valorNuevo, columnInfo[cambio.campo].type): cambio.valorNuevo}`}</span><br />
																	<br />
																</div>
															))}
														</Card.Body>
													</Card>
												) : change.operacion === 'insert' ? (
													<Card style={{ position: 'relative' }}>
														<div className="d-flex justify-content-between align-items-center p-2 bg-light">
															<span>Se ha creado {model} _ID:{change._id}</span>
															<span>{moment.utc(change.createdAt).format('DD-MM-YYYY HH:mm')}</span>
														</div>
													</Card>
												) : change.operacion === 'delete' ? (
													<Card style={{ position: 'relative' }}>
														<div className="d-flex justify-content-between align-items-center p-2 bg-light">
															<span>Se ha eliminado  {model} _ID:{change._id}</span>
															<span>{moment.utc(change.createdAt).format('DD-MM-YYYY HH:mm')}</span>
														</div>
													</Card>
												) : null}
											</Col>
										</Row>
									))
								) : (
									<MessageBox variant='danger'>No hay cambios registrados</MessageBox>
								)}
							</>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={handleClose}>Cerrar historial</Button>
						</Modal.Footer>
					</Modal>)
			}
		</>
	);
}

export default HistoryModal;
