import React, { useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import DynamicListTableNew from '../../components/DynamicListTableNew';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import relWarrantyAssetService from '../../services/relWarrantyAssets.service';
import { getError } from '../../utils';
import { relWarrantiesColumns } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, relWarranties: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true,
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	default:
		return state;
	}
};

function CreditWarranties() {
	const [{ loading, error, relWarranties, successDelete }, dispatch] = useReducer(reducer, {
		relWarranties: [],
		loading: true,
		error: '',
	});


	const params = useParams();
	const { id } = params;

	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				const { data } = await relWarrantyAssetService.getDetails(id);
				dispatch({ type: 'FETCH_SUCCESS', payload: data });
			} catch (err) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
			}
		};
		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		} else {
			fetchData();
		}
	}, [successDelete]);

	async function deleteWarrantyHandler(relWarranty) {
		if (window.confirm('Seguro desea eliminar garantia?')) {
			try {
				dispatch({ type: 'DELETE_REQUEST' });
				await relWarrantyAssetService.delete(relWarranty._id);
				dispatch({ type: 'DELETE_SUCCESS' });
				toast.success('Garantia Eliminada');
			} catch (err) {
				toast.error(getError(err));
				dispatch({ type: 'DELETE_FAIL' });
			}
		}
	}

	return loading ? (
		<LoadingBox></LoadingBox>
	) : error ? (
		<MessageBox variant='danger'>{error}</MessageBox>
	) : (
		<div>
			<div className='container admin-con'>
				<DynamicListTableNew
					data={relWarranties}
					loading={loading}
					error={error}
					actionButtons={{
						deleteHandler: deleteWarrantyHandler,
					}}
					dataName='relWarranties'
					showCheckboxColumn={false}
					frontPaginator={true}
					exportOption={true}
					columnInfo={relWarrantiesColumns}
				/>
			</div>
		</div>
	);
}

export default CreditWarranties;
