import axiosInstance from '../RestClient';

const BASE_URI = '/models';

class ModelService {
	async list() {
		const uri = `${BASE_URI}/`;
		const response = await axiosInstance.get(uri);
		return response;
	}
	async saveConfig(modelName, selectedFields) {
		const uri = `${BASE_URI}/`;
		const response = await axiosInstance.post(uri, { modelName, selectedFields });
		return response;
	}
}

export default new ModelService();