import React, { useEffect, useReducer,useState } from 'react';
import ModelService from '../../services/model.service';
import { Button,Collapse,Card, Container, Table} from 'react-bootstrap';
import { getError } from '../../utils';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, modelsDetail: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'SAVE_REQUEST':
		return { ...state, loadingSave: true };
	case 'SAVE_SUCCESS':
		return { ...state, loadingSave: false };
	case 'SAVE_FAIL':
		return { ...state, loadingSave: false, error: action.payload };
	default:
		return state;
	}
};

function AdminHistoryLog() {
	const [{ loading,loadingSave, error, modelsDetail }, dispatch] = useReducer(reducer, {
		modelsDetail: [],
		loading: true,
		loadingSave:false,
		error: '',
	});

	const [selectedFields,setSelectedFields] = useState([]);
	const [openModel, setOpenModel] = useState(null);


	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				const {data} = await ModelService.list();
				dispatch({ type: 'FETCH_SUCCESS', payload: data });
				const initialSelectedFields = {};
				data.forEach((model) => {
					initialSelectedFields[model.model] = model.selectedFields || [];
				});
				setSelectedFields(initialSelectedFields);
			} catch (err) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
			}
		};

		fetchData();
	}, []);

	const handleCheckboxChange = (model, field) => {
		setSelectedFields(prevSelectedFields => {
			const modelFields = prevSelectedFields[model] || [];
			const isChecked = modelFields.includes(field);
			if (isChecked) {
				return {
					...prevSelectedFields,
					[model]: modelFields.filter(f => f !== field),
				};
			} else {
				return {
					...prevSelectedFields,
					[model]: [...modelFields, field],
				};
			}
		});
	};

	const handleSaveConfig = async (modelName) => {
		try {
			dispatch({ type: 'SAVE_REQUEST' });
			await ModelService.saveConfig(modelName, selectedFields);
			dispatch({ type: 'SAVE_SUCCESS' });
		} catch (err) {
			dispatch({ type: 'SAVE_FAIL', payload: getError(err) });
		}};

	const toggleModelDetails = (modelName) => {
		setOpenModel(prevOpenModel => (prevOpenModel === modelName ? null : modelName));};

	return loading ||loadingSave ? (
		<LoadingBox></LoadingBox>
	) : error ? (
		<MessageBox variant='danger'>{error}</MessageBox>
	) : (
		<div>
			{modelsDetail.map((model) => (
				<Card key={model.model}className="mb-3">
					<Button
						className={`arrow-button ${openModel === model.model ? '' : 'collapsed'}`}
						onClick={() => toggleModelDetails(model.model)}
						variant='light'
						aria-controls={`collapse-${model.model}`}
						aria-expanded={openModel === model.model}
					>           
						{model.model}
					</Button>
					<Collapse in={openModel === model.model}>
						<Container id={`collapse-${model.model}`}>
							<div className="d-flex flex-column align-items-center">
								<Table bordered>
									<thead>
										<tr>
											<th>Campo</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										{model.fields.map((field) => (
											<tr key={field.name}>
												<td>{field.name}</td>
												<td>
													<input
														type="checkbox"
														id={`${model.model}-${field.name}`}
														onChange={() => handleCheckboxChange(model.model, field.name)}
														checked={selectedFields[model.model]?.includes(field.name) || false}
													/>
												</td>
											</tr>
										))}
									</tbody>
								</Table>
							</div>
							<div className="d-flex justify-content-end m-3">
								<Button
									onClick={() => handleSaveConfig(model.model)}>Guardar seguimiento de {model.model}
								</Button>
							</div>
						</Container>
					</Collapse>
				</Card>
			))}
		</div>
	);
}

export default AdminHistoryLog;
